import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/IndexView.vue"),
  },
  {
    path: "/phenonet",
    name: "phenonet",
    component: () => import("../views/PhenoNetView.vue"),
  },
  {
    path: "/phenosr",
    name: "phenosr",
    component: () => import("../views/PhenoSRView.vue"),
  },
  {
    path: "/osnet",
    name: "osnet",
    component: () => import("../views/OSNetView.vue"),
  },
  {
    path: "/wheatpheno",
    name: "wheatpheno",
    component: () => import("../views/WheatPhenoView.vue"),
  },
  {
    path: "/wheatpheno2",
    name: "wheatpheno2",
    component: () => import("../views/WheatPheno2View.vue"),
  },
    {
    path: "/plotsegmentation",
    name: "plotsegmentation",
    component: () => import("../views/PlotSegmentation.vue"),
  },
  {
    path: "/phenotools",
    name: "phenotools",
    component: () => import("../views/PhenoToolsView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/myphenonet",
    name: "myphenonet",
    component: () => import("../views/MyPhenoNetView.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/HelpView.vue"),
  },
  {
    path: "/land",
    name: "land",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import("../views/ForgotView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/TermsView.vue"),
  },
  {
    path: "/resetpwd/:pathMath(.*)",
    name: "resetpwd",
    component: () => import("../views/ResetPwdView.vue"),
  },
  {
    path: "/verify/:pathMath(.*)",
    name: "verify",
    component: () => import("../views/VerifyView.vue"),
  },
  {
    path: "/:pathMath(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
//当路由开始跳转时
router.beforeEach((to, from, next) => {
  // 开启进度条
  NProgress.start();
  // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  next();
});
//当路由跳转结束后
router.afterEach(() => {
  // 关闭进度条
  NProgress.done();
  // 切换路由之后滚动条始终在最顶部
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  });
});

export default router;
